<template>
    <div class="comp-Repair">
        <el-card>
            <div slot="header">维修记录</div>

            <el-button type="primary" @click="addOrUpdateHandle()" style="margin-bottom: 10px">新增</el-button>

            <el-table :data="propCarRepairs" border>
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column label="情况描述" prop="qkms" align="center"></el-table-column>
                <el-table-column label="原因分析" prop="yyfx" align="center"></el-table-column>
                <el-table-column label="维修人" prop="wxr" align="center"></el-table-column>
                <el-table-column label="质检人" prop="zjr" align="center"></el-table-column>
                <el-table-column label="进厂时间" prop="intoDate" align="center"></el-table-column>
                <el-table-column label="出厂时间" prop="outDate" align="center"></el-table-column>
                <el-table-column label="金额" prop="money" align="center"></el-table-column>

                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
                        <el-button type="danger" size="mini" @click="deleteHandle(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <!-- 新增or修改组件 -->
        <add-or-update v-if="aUpd_visible" ref="AddOrUpdateDom" @refreshDataList="$emit('refreshCarInfo')" />
    </div>
</template>

<script>
import AddOrUpdate from './repair-add-or-update'

export default {
    components: {
        AddOrUpdate
    },
    data() {
        return {
            aUpd_visible: false,
        }
    },
    props: ['propCarRepairs', 'carId'],
    created() {
    },
    methods: {
        // 新增维修记录
        addOrUpdateHandle(id) {
            this.aUpd_visible = true;

            this.$nextTick(() => {
                this.$refs['AddOrUpdateDom'].init(this.carId, id)
            })
        },

        // 删除维修记录
        deleteHandle(id) {
            this.$confirm(`确定要进行删除吗？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http.carrepair.delete({
                    id,
                }).then((res) => {
                    console.log(res)
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.$emit('refreshCarInfo')
                        },
                    });
                });
            });
        }

    }
}
</script>
