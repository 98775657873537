<template>
    <el-dialog :visible.sync="visible" :title="dataForm.id? '修改':'新增'" :append-to-body="true">
        <el-form :model="dataForm">

            <el-descriptions :column="2" border>
                <el-descriptions-item label="情况描述">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        情况描述
                    </template>

                    <el-form-item prop="qkms">
                        <el-input v-model="dataForm.qkms" placeholder="情况描述" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="原因分析">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        原因分析
                    </template>

                    <el-form-item prop="yyfx">
                        <el-input v-model="dataForm.yyfx" placeholder="原因分析" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="维修人">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        维修人
                    </template>

                    <el-form-item prop="wxr">
                        <el-input v-model="dataForm.wxr" placeholder="请输入维护人" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="质检人">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        质检人
                    </template>

                    <el-form-item prop="zjr">
                        <el-input v-model="dataForm.zjr" placeholder="请输入质检人" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="金额">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        金额
                    </template>

                    <el-form-item prop="money">
                        <el-input v-model="dataForm.money" placeholder="金额" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="进厂时间">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        进厂时间
                    </template>

                    <el-form-item prop="intoDate">
                        <el-date-picker v-model="dataForm.intoDate" type="datetime" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="请输入进场时间"></el-date-picker>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="出厂时间">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        出厂时间
                    </template>

                    <el-form-item prop="outDate">
                        <el-date-picker v-model="dataForm.outDate" type="datetime" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="请输入出场时间"></el-date-picker>
                    </el-form-item>
                </el-descriptions-item>

            </el-descriptions>
        </el-form>

        <span slot="footer">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="addMaintainHandler" v-if="!dataForm.id">新增</el-button>
            <el-button type="primary" @click="updateMaintainHandler" v-else>修改</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                id: '',
                cid: '',
                qkms: '',
                yyfx: '',
                wxr: '',
                zjr: '',
                intoDate: '',
                outDate: '',
                money: '',
            }
        }
    },
    methods: {
        init(carId, id) {
            this.initData()

            this.visible = true;

            if (carId) {
                this.dataForm.cid = carId
            }
            if (id) {
                this.dataForm.id = id;
                this.getRepairInfo()
            }
        },
        initData() {
            Object.keys(this.dataForm).forEach(key => {
                this.dataForm[key] = ''
            })

        },
        // 新增维修记录
        addMaintainHandler() {
            const _this = this;

            this.$http.carrepair.save(this.dataForm).then(res => {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                    duration: 1000,
                    onClose() {
                        _this.visible = false;
                        _this.$emit('refreshDataList')
                    }
                })
            })
        },

        // 新增维修记录
        updateMaintainHandler() {
            const _this = this;

            this.$http.carrepair.update(this.dataForm).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 1000,
                    onClose() {
                        _this.visible = false;
                        _this.$emit('refreshDataList')
                    }
                })
            })
        },

        // 获取维护记录信息
        getRepairInfo() {
            this.$http.carrepair.info({ id: this.dataForm.id }).then(res => {
                this.dataForm = res.data || this.dataForm;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
:deep() {
    .el-descriptions {
        .el-descriptions-item__label {
            width: 100px;
            text-align: center;
            color: #606266;
        }

        .el-descriptions-item__content {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .el-select {
        width: 100%;
    }

    .el-date-editor {
        width: 100%;
    }

    .el-form-item {
        margin-bottom: 0;
    }
}
</style>